.my_portfolio_container {
  background: var(--bg-color);
}

.image-gallery-image {
  padding: 3px ;
  background-color: var(--secondary-color);

}

