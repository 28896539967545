.aga_overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;
}

.aga_modal {
  position: fixed;
  width: 100%;
  top: 0%;
  left: 0%;
  background-color: var(--primary-color);
  z-index: 10000;
}
