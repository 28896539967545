.my_button {
  color: var(--link-color);
  background-color: transparent;
  border: none;
  padding: 0px;
}

.my_button:focus,
.my_button:hover {
  color: var(--text-color);
  box-shadow: unset;
  text-decoration: underline;
}

.grecaptcha-badge {
  visibility: hidden;
}
